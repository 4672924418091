import request from '../utils/request';

export function getCarrierList(param) {
    return request({
        url: '/carrier-list',
        method: 'GET',
        params: param
    })
}

export function saveCarrier(data, id) {
    return request({
        url: '/carrier/'+id,
        method: 'POST',
        data
    })
}

export function deleteCarrier(id) {
    return request({
        url: '/carrier/'+id,
        method: 'DELETE'
    })
}

export function setCarrierSort(id, sort) {
    return request({
        url: '/carrier/'+id+'/sort/'+sort,
        method: 'PUT'
    })
}

export function setCarrierIsvalidate(id, isvalidate) {
    return request({
        url: '/carrier/'+id+'/isvalidate/'+isvalidate,
        method: 'PUT'
    })
}
